import React from 'react'
import img6 from './images/Invest-in-Mutual-Funds_thumbnail.jpg'
import {Link} from 'react-router-dom'

const DpRp = () => {
  return (
    <div className="mf-directly-container">
    <h2 className="mf-title">What are the different ways of investing in Mutual Funds?</h2>
    <div className="mf-content">
      <div className="mf-image">
        <img 
          src={img6} 
          alt="How can I invest in Mutual Funds directly?" 
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className="mf-description">
        <p>
        One can invest in Mutual Funds by submitting a duly completed application form along with a cheque or bank draft at the branch office or designated Investor Service Centres (ISC) of Mutual Funds or Registrar & Transfer Agents of the respective the Mutual Funds.</p>
        <p>
        One may also choose to invest online through the websites of the respective Mutual Funds.         </p>
        <p>
        Thus if an investor chooses to invest directly through the Direct Plan, she may get marginally higher return on account of savings in expenses, but she would not be able to avail of distribution and related services of an intermediary.Further, one may invest with the help of / through a financial intermediary i.e., a Mutual Fund Distributor registered with AMFI OR choose to invest directly i.e., without involving or routing the investment through any distributor.    
            </p>
            <p>
            A Mutual Fund Distributor may be an individual or a non-individual entity, such as bank, brokering house or on-line distribution channel provider.
            </p>
            <p>
            One can choose to invest online, as platforms these days have all necessary safeguards to ensure secure investing. It is really more a matter of comfort and convenience
            </p>
        <Link to='/contact'>
          <button className="invest-button">I'M READY TO INVEST</button>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default DpRp