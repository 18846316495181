import React from 'react'
import img2 from './images/11-Why-is-Investing-Better-than-saving_2.png'
import {Link} from 'react-router-dom'

const Invest = () => {
  return (
    <div className="mf-directly-container">
    <h2 className="mf-title">Why is investing better than saving?</h2>
    <div className="mf-content">
      <div className="mf-image">
        <img 
          src={img2} 
          alt="How can I invest in Mutual Funds directly?" 
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className="mf-description">
        <p>
        Imagine a 50-overs cricket match in which #6 batsman walks in to bat only in the 5th over. His job is to first ensure he does not lose the wicket, and then focus on scoring runs.        </p>
        <p>
        While saving is a must for investing, it is important to save one’s wicket in order to be able to score later. One can save the wicket by playing defensive cricket and avoiding all sorts of shots. But that would result in a very low score. He would need to hit some boundaries by taking certain risks like lofted shots or drives between fielders or cuts and nudges.        </p>
        <p>
        Similarly, in order to accumulate large sums to meet one’s <span className='highlight'>financial goals</span>, in order to beat <span className='highlight'>inflation</span>, one must take certain investment risks. Investing is all about taking calculated risks and managing the same, not avoiding the risks altogether.      
          </p>
          <p>
          At the same time, in the cricket analogy, in order to stay at the crease as well as score runs, one must take calculated risks and not play rash shots. Taking unnecessary risks is a bad strategy             </p>

        <Link to='/contact'>
          <button className="invest-button">I'M READY TO INVEST</button>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default Invest