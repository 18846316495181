import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaGlobe, FaRocket, FaBriefcase, FaMobile, FaWhatsapp } from 'react-icons/fa';
import Videos from './Videos';

// Define responsive breakpoints
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional
  }
};

const Testimonial = () => {
  return (
<>

  <div className="testimonials py-lg-5" id="testi">
      <div className="container py-5">
        <div className="title-section pb-4">
          <h3 className="main-title-agile text-black">What People Say About Us</h3>
        </div>
        <div className="text-center py-lg-5">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={true}
            swipeable={true}
            draggable={true}
            className="testi-carousel"
          >
            <div className="testi-agile">
              <p>
                <FaQuoteLeft />Rahul Financial Services provided exceptional support with my insurance and mutual fund investments. Their expertise and personalized approach made everything easy to understand and manage. Highly recommend their services!<FaQuoteRight />
              </p>
              <div className="testi-pos">
                <h4>Dayanand Keswani</h4>
              
              </div>
            </div>
            <div className="testi-agile">
              <p>
                <FaQuoteLeft />Rahul Financial Services has been a tremendous help with my insurance and mutual fund needs. Their knowledgeable team made complex financial decisions simple and straightforward. I trust them completely with my financial future!<FaQuoteRight />
              </p>
              <div className="testi-pos">
                <h4>Dr.Devidas Sarode</h4>
              </div>
            </div>
            <div className="testi-agile">
              <p>
                <FaQuoteLeft />I’m really impressed with the service from Rahul Financial Services. They handled my insurance and mutual fund investments with professionalism and clarity. Their expertise made the process smooth and stress-free. Highly recommend them to anyone looking for dependable financial advice!<FaQuoteRight />
              </p>
              <div className="testi-pos">
                <h4>John Arim</h4>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
    
   

    <div className="agile-works py-5" id="work">
    <div className="container py-lg-5">
      <div className="title-section pb-4">
        <h3 className="main-title-agile">how it works?</h3>
      
      </div>
      <div className="row py-md-5 pt-5">
        <div className="col-md-12">
          <div className="main-timeline2">
            <div className="timeline">
              <span className="icon"><FaGlobe /></span>
              <a href="#" className="timeline-content">
                <h3 className="title"> Choose Your Fund</h3>
                <p className="description">
                Begin by researching various mutual funds. Evaluate based on risk, returns, and fund manager expertise. Select a fund that aligns with your financial goals                  </p>
              </a>
            </div>
            <div className="timeline">
              <span className="icon"><FaRocket /></span>
              <a href="#" className="timeline-content">
                <h3 className="title">Invest in Your Chosen Fund</h3>
                <p className="description">
                Invest a lump sum or set up a Systematic Investment Plan (SIP). Start small or big, depending on your comfort and financial plan.                  </p>
              </a>
            </div>
            <div className="timeline">
              <span className="icon"><FaBriefcase /></span>
              <a href="#" className="timeline-content">
                <h3 className="title">Track Your Investment</h3>
                <p className="description">
                Regularly monitor the performance of your investments. Use fund statements and online tools to keep an eye on returns and market trends.                  </p>
              </a>
            </div>
            <div className="timeline">
              <span className="icon"><FaMobile /></span>
              <a href="#" className="timeline-content">
                <h3 className="title">Redeem Your Returns</h3>
                <p className="description">
                When your investment goal is achieved, redeem your units. Withdraw profits or reinvest to continue growing your wealth.                  </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Videos/>
  </div>

</>
  );
};

export default Testimonial;
