import React from 'react'
import bannerImg from '../src/images/banner.jpg';
import { FaGlobe, FaRocket, FaBriefcase, FaMobile, FaWhatsapp } from 'react-icons/fa';
import statsBackgroundImg from '../src/images/slide.jpg';
import foot from '../src/images/bg.jpg'
import './Homepage.css'
import img1 from './images/08-What-is-SIP_new.png'
import img2 from './images/11-Why-is-Investing-Better-than-saving_2.png'
import img3 from './images/01-What-is-a-Mutual-Fund_1.png'
import img4 from './images/65.How-can-I-invest-in-Mutual-Funds-directly.png'
import img5 from './images/02-How-to-build-retirement-corpus-with-Mutual-Funds-525x325-Thumbnail.jpg'
import img6 from './images//Invest-in-Mutual-Funds_thumbnail.jpg'
import { Link } from 'react-router-dom';
import video from './videos/Market Loop Background Video - High Resolution.mp4'
import Services from './Services';


const HomePage = () => {
    const bannerStyle = {
        background: video,
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: 0,
        height: '100vh', // Adjust the height as needed
      };

      const statsStyle = {
        background: `url(${statsBackgroundImg}) center no-repeat`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        position: 'relative',
        zIndex: 0,
        
      };
  
     
  
  return (
    <>
     {/* Banner */}
     <video className="w-100" autoPlay muted loop>
      <source src={video} type="video/mp4" /> 
    </video>

     {/* //banner */}

     <div class="mutual-funds-section py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-4">
        <Link to='/SIP'>
        <div class="fund-card" >
          <img src={img1} alt="Character Selection" class="img-fluid" />
          <h4>What is Systematic Investment Plan (SIP)?</h4>
        </div>
        </Link>
      </div>
      <div class="col-md-4 mb-4">
       <Link to='/invest'>
       <div class="fund-card">
          <img src={img2} alt="Calculate Retirement Needs" class="img-fluid" />
          <h4>Why is investing better than saving?</h4>
        </div>
       </Link>
      </div>
      <div class="col-md-4 mb-4">
      <Link to="/mf" >
        <div class="fund-card">
          <img src={img3} alt="What is a Mutual Fund?" class="img-fluid" />
          <h4>What is a Mutual Fund?</h4>
        </div>
      </Link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-4">
       <Link to="/mf-directly" >
       <div class="fund-card">
          <img src={img4} alt="Invest in Mutual Funds" class="img-fluid" />
          <h4>How can I invest in Mutual Funds directly?</h4>
        </div>
       </Link>
      </div>
      <div class="col-md-4 mb-4">
       <Link to='/retirement'>
       <div class="fund-card">
          <img src={img5} alt="Build Retirement Corpus" class="img-fluid" />
          <h4>How to build retirement corpus with Mutual Funds?</h4>
        </div>
       </Link>
      </div>
      <div class="col-md-4 mb-4">
       <Link to='dprp'>
       <div class="fund-card">
          <img src={img6} alt="Systematic Investment Plan (SIP)" class="img-fluid" />
          <h4>What are the different ways of investing in Mutual Funds?</h4>
        </div>
       </Link>
      </div>
    </div>
  </div>
</div>
<Services/>

      <div className="agile-works py-5" id="work">
      <div className="container py-lg-5">
        <div className="title-section pb-4">
          <h3 className="main-title-agile">how it works?</h3>
          <span className="title-line"></span>
        </div>
        <div className="row py-md-5 pt-5">
          <div className="col-md-12">
            <div className="main-timeline2">
              <div className="timeline">
                <span className="icon"><FaGlobe /></span>
                <a href="#" className="timeline-content">
                  <h3 className="title"> Choose Your Fund</h3>
                  <p className="description">
                  Begin by researching various mutual funds. Evaluate based on risk, returns, and fund manager expertise. Select a fund that aligns with your financial goals                  </p>
                </a>
              </div>
              <div className="timeline">
                <span className="icon"><FaRocket /></span>
                <a href="#" className="timeline-content">
                  <h3 className="title">Invest in Your Chosen Fund</h3>
                  <p className="description">
                  Invest a lump sum or set up a Systematic Investment Plan (SIP). Start small or big, depending on your comfort and financial plan.                  </p>
                </a>
              </div>
              <div className="timeline">
                <span className="icon"><FaBriefcase /></span>
                <a href="#" className="timeline-content">
                  <h3 className="title">Track Your Investment</h3>
                  <p className="description">
                  Regularly monitor the performance of your investments. Use fund statements and online tools to keep an eye on returns and market trends.                  </p>
                </a>
              </div>
              <div className="timeline">
                <span className="icon"><FaMobile /></span>
                <a href="#" className="timeline-content">
                  <h3 className="title">Redeem Your Returns</h3>
                  <p className="description">
                  When your investment goal is achieved, redeem your units. Withdraw profits or reinvest to continue growing your wealth.                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="contact-wthree" id="contact">
      <div className="container py-sm-5">
        <div className="title-section py-4">
          <h3 className="main-title-agile">contact us</h3>
        
        </div>
        <div className="row py-lg-5 py-4">
          <div className="col-lg-4">
            <div className="agile-contact-top">
              <h4>get in touch</h4>
              <p>shop no 36,Opposite Gokul Sweet Mart,Ring Rd, Ganesh Colony, Jalgaon, Maharashtra 425001</p>
            </div>
            <hr />
            <p>            If you have any questions or need assistance, our team at Beyond is here to help. You can reach us by phone or through our online contact form. For a callback, simply fill out the form below, and we'll get in touch with you as soon as possible. Stay connected with us on social media for the latest updates and information. Thank you for choosing Beyond!
            </p>
            <div className="d-sm-flex">
             <a href="tel:+919158977797" role="button" className="btn btn-primary mt-4 ml-3 agile-link-bnr scroll text-white">Call Now</a>
          </div>
          </div>
          <div className="offset-2"></div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            {/* register form grid */}
            <div className="register-top1">
              <form action="#" method="get" className="register-wthree">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>First name</label>
                      <input className="form-control" type="text" placeholder="Johnson" name="first-name" required />
                    </div>
                    <div className="col-md-6 mt-md-0 mt-4">
                      <label>Last name</label>
                      <input className="form-control" type="text" placeholder="Kc" name="last-name" required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Mobile</label>
                      <input className="form-control" type="text" placeholder="xxxx xxxxx" name="mobile" required />
                    </div>
                    <div className="col-md-6 mt-md-0 mt-4">
                      <label>Email</label>
                      <input className="form-control" type="email" placeholder="example@email.com" name="email" required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Your message</label>
                      <textarea placeholder="Type your message here" className="form-control"></textarea>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-agile btn-block w-100 font-weight-bold text-uppercase">Send</button>
                  </div>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
    </div>

     </>

  )
}

export default HomePage