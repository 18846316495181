import React, { useState } from 'react';
import contactimage from './images/download.png';
import './ContacrPage.css';
import { Link } from 'react-router-dom';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { firstName, lastName, mobile, email, message } = formData;
    const whatsappMessage = `
      I Need More info About Your Financial Services
      First Name: ${firstName}\n
      Last Name: ${lastName}\n
      Mobile: ${mobile}\n
      Email: ${email}\n
      Message: ${message}
    `;

    const whatsappUrl = `https://wa.me/9158977797?text=${encodeURIComponent(whatsappMessage)}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <div className="contact-wthree" id="contact">
        <img src={contactimage} className='header-img' alt="Contact" />
       
        <div className="container py-sm-5">
          <div className="title-section py-4">
            <h3 className="main-title-agile">Contact Us</h3>
          </div>
          <div className="row py-lg-5 py-4">
            <div className="col-lg-4">
              <div className="agile-contact-top">
                <h4>Get in Touch</h4>
                <p>Shop no 36, Opposite Gokul Sweet Mart, Ring Rd, Ganesh Colony, Jalgaon, Maharashtra 425001</p>
              </div>
              <hr />
              <p>
                If you have any questions or need assistance, our team at Beyond is here to help. You can reach us by phone or through our online contact form. For a callback, simply fill out the form below, and we'll get in touch with you as soon as possible. Stay connected with us on social media for the latest updates and information. Thank you for choosing Beyond!
              </p>
              <div className="d-sm-flex">
                <a href="tel:+919158977797" role="button" className="btn btn-primary mt-4 ml-3 agile-link-bnr scroll text-white">Call Now</a>
              </div>
            </div>
            <div className="offset-2"></div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <div className="register-top1">
                <form onSubmit={handleSubmit} className="register-wthree">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>First name</label>
                        <input 
                          className="form-control" 
                          type="text" 
                          placeholder="Firstname" 
                          name="firstName" 
                          value={formData.firstName}
                          onChange={handleChange}
                          required 
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Last name</label>
                        <input 
                          className="form-control" 
                          type="text" 
                          placeholder="Lastname" 
                          name="lastName" 
                          value={formData.lastName}
                          onChange={handleChange}
                          required 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Mobile</label>
                        <input 
                          className="form-control" 
                          type="text" 
                          placeholder="xxxx xxxxx" 
                          name="mobile" 
                          value={formData.mobile}
                          onChange={handleChange}
                          required 
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Email</label>
                        <input 
                          className="form-control" 
                          type="email" 
                          placeholder="example@email.com" 
                          name="email" 
                          value={formData.email}
                          onChange={handleChange}
                          required 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Your message</label>
                        <textarea 
                          placeholder="Type your message here" 
                          className="form-control"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-agile btn-block w-100 font-weight-bold text-uppercase">Send</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
