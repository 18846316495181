// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header'; // Correct path
import HomePage from './HomePage'; // Correct path
import AboutPage from './AboutPage'; // Correct path
import ContactPage from './ContactPage'; // Correct path
import Footer from './Footer';
import Services from './Services'
import Testimonial from './Testimonial';
import MFDirectly from './MFDirectly';
import MutualFund from './MutualFund';
import SIP from './SIP';
import Invest from './Invest';
import Retirement from './Retirement';
import DpRp from './DpRp';


function App() {
  return (
    <Router>
     <Header/>
      <Routes>
        <Route path="/" element={<HomePage />} />
         <Route path="/about" element={<AboutPage/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/contact" element={<ContactPage/>} /> 
        <Route path="/testimonial" element={<Testimonial/>} /> 
        <Route path="/mf-directly" element={<MFDirectly/>} />
        <Route path="/mf" element={<MutualFund/>} />
        <Route path="/SIP" element={<SIP/>} />
        <Route path="/invest" element={<Invest/>} />
        <Route path="/retirement" element={<Retirement/>} />
        <Route path="/dprp" element={<DpRp/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
