import React from 'react'
import img5 from './images/02-How-to-build-retirement-corpus-with-Mutual-Funds-525x325-Thumbnail.jpg'
import {Link} from 'react-router-dom'

const Retirement = () => {
  return (
    <div className="mf-directly-container">
      <h2 className="mf-title">How to build retirement corpus with Mutual Funds?</h2>
      <div className="mf-content">
        <div className="mf-image">
          <img 
            src={img5} 
            alt="How can I invest in Mutual Funds directly?" 
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <div className="mf-description">
          <p>
          Most people don’t realize that their retired lives can be as long as their working life and they’ll need an enormous corpus to last at least 25-30 years. Without proper financial planning, your savings may not be sufficient to cover all expenses and emergency needs. But how do you build a corpus to sustain 25-30 years of retired life? Firstly, figure out what your annual expenses are going to look like after retirement using our Inflation calculator and decide on the total corpus you will need to sustain 25-30 years of your retired life.The advantage of investing in mutual fundsthrough SIP is that it doesn’t pinch your wallet and can be managed from your monthly income.           </p>
          <p>
          Next, choose few mutual fund schemes for long-term growth depending on your risk appetite. While equity funds are recommended for long-term, you can choose debt or hybrid funds as well. Align your return expectations with the category and type of mutual fund schemes you choose.           </p>
          <p>
          Well begun is half done. By bringing in discipline early in the best possible manner, it will take less effort to achieve your financial goal of a happy retired life.          </p>
          <Link to ='/contact'>
            <button className="invest-button">I'M READY TO INVEST</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Retirement