import React from 'react'
import img1 from './images/08-What-is-SIP_new.png'
import {Link} from 'react-router-dom'

const SIP = () => {
  return (
    <div className="mf-directly-container">
      <h2 className="mf-title">What is Systematic Investment Plan (SIP)?</h2>
      <div className="mf-content">
        <div className="mf-image">
          <img 
            src={img1} 
            alt="How can I invest in Mutual Funds directly?" 
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <div className="mf-description">
          <p>
          Systematic Investment Plan (SIP) is an investment route offered by Mutual Funds wherein one can invest a fixed amount in a <span className="highlight">Mutual Fund scheme</span> at regular intervals– say once a month or once a quarter, instead of making a lump-sum investment. The installment amount could be as little as INR 500 a month and is similar to a recurring deposit. It’s convenient as you can give your bank standing instructions to debit the amount every month.
          </p>
          <p>
          <span className="highlight">SIP</span> SIP has been gaining popularity among Indian MF investors, as it helps in investing in a disciplined manner without worrying about market volatility and timing the market. Systematic Investment Plans offered by <span className='highlight'>Mutual Funds</span>are easily the best way to enter the world of investments for the long term. It is very important to<span className='highlight'>invest for the long-term,</span> which means that you should start investing early, in order to maximize the end returns. So your mantra should be - Start Early, Invest Regularly to get the best out of your investments.
          </p>
          <Link to='/contact'>
            <button className="invest-button">I'M READY TO INVEST</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SIP