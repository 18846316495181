// src/components/Footer.js
import React from 'react';
import foot from '../src/images/bg.jpg';
import './Footer.css';
import {Link} from 'react-router-dom'

const Footer = () => {
  const footerStyle = {
    background: `url(${foot}) center no-repeat`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    position: 'relative',
    zIndex: 0,
    padding: '20px 0',
    color: '#fff',
  };

  return (
   <>
    <footer className="footer" style={footerStyle}>
      <div className="container">
        <div className="footer-content">
          <div className="footer-section about">
            <h2>About Us</h2>
            <p>We are a leading mutual fund company committed to helping our clients achieve their financial goals through professional investment management and personalized financial advice.</p>
            <Link to="/about">Learn More</Link>
          </div>

          <div className="footer-section ">
            <h2>Links</h2>
            <ul>
              <li> <a href="https://www.cvlkra.com/kycpaninquiry.aspx">Check KYC</a><a href="https://www.bseindia.com/">  | BSE</a> </li>
              <li><a href="https://www.sebi.gov.in/">SEBI</a> <a href="/fhttps://www.amfiindia.com/y"> | AMFI</a><a href="https://www.nseindia.com/"> | NSE</a> </li>   
              <li><a href="https://www.rbi.org.in/">RBI</a> <a href="https://irdai.gov.in/"> | IRDA</a> <a href="https://fpsb.org/member/financial-planning-standards-board-india/"> | FPSBI</a></li>
     
            </ul>
          </div>

          <div className="footer-section quick-links">
            <h2>Quick Links</h2>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>

          <div className="footer-section contact">
            <h2>Contact Us</h2>
            <p>Email: <a href="mailto:welcome@mutualfundcompany.com">welcome@financewithrahul.in</a></p>
            <p>Phone: <a href="tel:091589 77797">091589 77797</a></p>
            <p>Address: shop no, Khandesh Mill Colony, 36, Ring Rd, Ganesh Colony, Jalgaon, Maharashtra 425001</p>
          </div>
        </div>

       
      </div>
     
    </footer>
    <div className="cpy-right">
          <p>© 2024 Rahul Chaudhri's financial services. All rights reserved | Design by <a href="https://www.shreedigimarketing.in">Shree Digital Marketing Agency</a></p>
        </div>
    </>
  );
};

export default Footer;
