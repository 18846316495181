import React from 'react';
import './Videos.css';

const Videos = () => {
  const videoList = [
    {
      id: 1,
      description: "Thermax | Capex Plans & Order Book Outlook | Ashish Bhandari | The Pearls of Fisdom | Nirmal Bang",
      embedUrl: "https://www.youtube.com/embed/cwDBM59vT2Q" // Correct embed URL
    },
    {
      id: 2,
      description: "Shree Renuka Sugars - Sugar Industry Outlook | Atul Chaturvedi | The Pearls of Fisdom | Nirmal Bang",
      embedUrl: "https://www.youtube.com/embed/wHfnc34-Tcw"
    },
    {
      id: 3,
      description: "Should you buy Gold this Akshaya Tritiya? | Kunal Shah | Ritu Poddar | Nirmal Bang ",
      embedUrl: "https://www.youtube.com/embed/Eii-FknwfEk?rel=0"
    },
    {
      id: 4,
      description: "JKumar Infra - Elections & Infra Sector boost | Nalin Gupta | The Pearls of Fisdom | Nirmal Bang",
      embedUrl: "https://www.youtube.com/embed/Wb4d98Mrqfc"
    },
    {
      id: 5,
      description: "Mutual Fund – What is it? Know about the mutual funds and it's futures.",
      embedUrl: "https://www.youtube.com/embed/puYqBIomTZc"
    },
    {
      id: 5,
      description: "Mutual Fund Outlook with Mr. Santosh Joseph - Germinate Investor Services LLP",
      embedUrl: "https://www.youtube.com/embed/cpONyrStdhc"
    }
    // Add more videos as needed
  ];

  return (
    <div className="videos-section">
      <h2>Videos</h2>
      <div className="video-grid">
        {videoList.map((video) => (
          <div className="video-item" key={video.id}>
            <div className="video-embed">
              <iframe 
                width="100%" 
                height="315" 
                src={video.embedUrl} 
                title={video.title} 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
           
            <p>{video.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Videos;
