import React from 'react';
import './AboutPage.css';
import about from './images/income-wealth-increase-vector-illustration-with-character-watering-growing-money-plant_675567-1822.avif';
// Importing images for differentiators
import value from './images/en-in-new-icon2.svg'
import nimble from './images/en-in-new-icon3.svg'
import customize from './images/investorservices-image9.svg'
import mf from './images/Mutual-Funds-772x485-1-600x390.jpg'

const AboutPage = () => {
  return (
    <div className="acontainer">
     
      <div className="image-container">
        <img src={about} alt="About Us" />
      </div>

   
      <div className="content-container">

        <section className="section mission">
          <h2>Our Mission</h2>
          <p>
            At Rahul Chaudhari's Financial Services, our mission is to empower individuals and businesses by providing
            exceptional mutual fund investment opportunities that cater to diverse financial goals.
          </p>
        </section>

        <section className="section history">
          <h2>Our History</h2>
          <p>
          Established in 1989, Rahul Chaudhari's Financial Services has grown from a small investment firm into a leading mutual fund provider. With over 35 years of experience, we've helped thousands of investors achieve their financial aspirations.
          </p>
        </section>

        <section className="section leadership">
          <h2>Our Leadership</h2>
          <p>
            Our leadership team comprises industry experts with decades of experience in financial 
            management and investment strategies. They are dedicated to ensuring the best outcomes for our clients.
          </p>
        </section>

      </div>
       {/* What Differentiates Us Section */}
       <section className="differentiate">
          <h2>What Differentiates Us?</h2>
          <div className="differentiators">
            <div className="differentiator">
              <img src={customize} alt="Value Creation" />
              <h3>Guided by long term value creation</h3>
              <p>We are a closely held public firm with a strong balance sheet, allowing us to invest with a long-term horizon.</p>
            </div>
            <div className="differentiator">
              <img src={value} alt="Nimble" />
              <h3>Nimble where it matters</h3>
              <p>At-scale capabilities in analytics, data and servicing combined with empowered teams make us uniquely agile.</p>
            </div>
            <div className="differentiator">
              <img src={nimble} alt="Customize" />
              <h3>Ability to Customize</h3>
              <p>Our tailored support through a global distribution platform, technological tools, and value-added services.</p>
            </div>
          </div>
        </section>

        <div className="mutual-funds-container">
    
      <div className="right-content">
     
        <h2>Mutual Funds</h2>
        <p>
          Mutual fund is a trust that pools money from a group of investors (sharing common financial goals) 
          and invests the money thus collected into asset classes that match the stated investment objectives 
          of the scheme. Since the stated investment objectives of a mutual fund scheme generally form the basis 
          for an investor's decision to contribute money to the pool, a mutual fund cannot deviate from its stated 
          objectives at any point in time. Every Mutual Fund is managed by a fund manager, who using his investment 
          management skills and necessary research work ensures much better return than what an investor can manage 
          on his own...
        </p>
        
        <p>
          When an investor subscribes for the units of a mutual fund, he becomes part owner of the assets of the fund 
          in the same proportion as his contribution amount put up with the corpus (the total amount of the fund). 
          Mutual Fund investor is also known as a mutual fund shareholder or a unit holder. Any change in the value 
          of the investments made into capital market instruments (such as shares, debentures, etc.) is reflected in 
          the Net Asset Value (NAV) of the scheme.
        </p>

        <ol>
          <li>If the market value of the assets of a fund is ₹100,000</li>
          <li>The total number of units issued to the investors is equal to 10,000</li>
          <li>Then the NAV of this scheme = (A)/(B) i.e. 100,000/10,000 or 10.0</li>
          <li>Now if an investor X owns 5 units of this scheme</li>
          <li>Then his total contribution to the fund is ₹50 (i.e., Number of units held multiplied by the NAV of the scheme)</li>
        </ol>
        
        <h5>Types of Mutual Funds Available:</h5>
        <ol>
          <li>
            <strong>Equity Schemes:</strong> These funds invest a maximum part of the funds into equity holdings. 
            They include diversified, mid-cap, sector-specific, and tax-saving schemes - 
            HDFC Equity Fund (mid-cap), Franklin India Bluechip (open-ended equity fund), HDFC Taxsaver, etc.
          </li>
          <li>
            <strong>Debt Funds:</strong> These funds invest in debt papers to reduce risk and provide stable income 
            to investors. They include gilt funds, income funds, MIPs, short-term plans, and liquid funds - 
            HDFC Floating Rate Income Ltd, Tata Gilt Retirement 280216, etc.
          </li>
          <li>
            <strong>Balanced Funds:</strong> These are a combination of equity and debt funds - 
            Tata Balanced, Birla Sun Life 95, etc.
          </li>
        </ol>
      </div>
    
    </div>
    
    </div>
    

    
  );
};

export default AboutPage;
