import React from 'react';
import './MFDirectly.css'
import img4 from './images/65.How-can-I-invest-in-Mutual-Funds-directly.png';
import {Link} from 'react-router-dom'

const MFDirectly = () => {
  return (
    <div className="mf-directly-container">
      <h2 className="mf-title">How can I invest in Mutual Funds directly?</h2>
      <div className="mf-content">
        <div className="mf-image">
          <img 
            src={img4} 
            alt="How can I invest in Mutual Funds directly?" 
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <div className="mf-description">
          <p>
            You can invest directly in a Mutual Fund either offline or online, if your KYC is complete. If you are uncomfortable transacting online, you can invest in a fund by visiting its nearest branch.
          </p>
          <p>
            Online is the most convenient way to invest directly in <span className="highlight">Mutual Fund schemes</span> and you get to save on commissions as well. You can invest online through a fund’s website or its RTA’s site or a fintech platform. Investing directly on a fund’s website requires you to manage multiple logins.
          </p>
          <p>
            Investing in a Direct Plan means you take responsibility for creating a financial plan, choosing funds most appropriate for your goals, monitoring the portfolio regularly to rebalance it if needed. Not everyone has enough knowledge on <span className="highlight">Mutual Funds</span> to choose the right funds and manage them effectively.
          </p>
          <Link to='/contact'>
            <button className="invest-button">I'M READY TO INVEST</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MFDirectly;
