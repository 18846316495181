import React from 'react'
import img3 from './images/01-What-is-a-Mutual-Fund_1.png'
import './MFDirectly.css'
import {Link} from 'react-router-dom'
const MutualFund = () => {
  return (
    <div className="mf-directly-container">
    <h2 className="mf-title">What is Mutual Fund?</h2>
    <div className="mf-content">
      <div className="mf-image">
        <img 
          src={img3} 
          alt="How can I invest in Mutual Funds directly?" 
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className="mf-description">
        <p>
        To many people, Mutual Funds can seem complicated or intimidating. We are going to try and simplify it for you at its very basic level. Essentially, the money pooled in by a large number of people (or investors) is what makes up a Mutual Fund. This fund is managed by a <span className="highlight">professional fund manager.</span>
        </p>
        <p>
        It is a trust that collects money from a number of investors who share a common investment objective. Then, it invests the money in equities, bonds, money market instruments and/or other securities. Each investor owns units, which represent a portion of the holdings of the fund. The income/gains generated from this collective investment is distributed proportionately amongst the investors after deducting certain expenses, by calculating a scheme’s <span className="highlight">Net Asset Value or NAV.</span>Simply put, a <span className='highlight'>Mutual Fund</span> is one of the most viable investment options for the common man as it offers an opportunity to invest in a diversified, professionally managed basket of securities at a relatively low cost.
        </p>
        <Link to='/contact'>
          <button className="invest-button">I'M READY TO INVEST</button>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default MutualFund